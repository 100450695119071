import React from 'react';
import BreadcrumbArea from './BreadcrumbArea';
import AboutArea from './AboutArea';
import TeamArea from './TeamArea';
import WorkProcessArea from './WorkProcessArea';
import QualityArea from './QualityArea'
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';
// import './App.css'; // Import your CSS file here
import Cta from '../Cta';
import Clients from '../Clients';

const About = () => {
    return (
        <>
            <div className="w-full">
                <NavBar />
            </div>
            <h1 class="sr-only">Best IT Company in Varanasi</h1>
            <p class="sr-only">Learn more about Masterwiz Technologies, the top digital transformation company in Varanasi, Uttar Pradesh. We are known for our innovative solutions, experienced team, and customer-centric approach.</p>

            <div id="about" className="w-full">
                <div className="w-full">
                    <BreadcrumbArea />
                </div>
                <div className="w-full">
                    <AboutArea />
                </div>
                <div className="w-full">
                    <QualityArea />
                </div>
                <div className="w-full">
                    <TeamArea />
                </div>
                <div className="w-full">
                    <WorkProcessArea />
                </div>
                <Clients />
                <Cta />
                <Footer />
            </div>
        </>
    );
};

export default About;
