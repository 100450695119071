import React from 'react';
import bannerThumb from '../../images/banner-thumb-4.png';
import { Link } from 'react-router-dom';

const BreadcrumbBanner = () => {
    return (
        <div className="breadcrumb-area breadcrumb-banner bg-gray-100 py-36 relative">
            <h1 class="sr-only">Custom Software Development in Varanasi</h1>
            <p class="sr-only">Masterwiz Technologies provides a range of services, including custom software development, mobile application development, e-commerce platforms, and digital marketing. Our expert team is based in Varanasi and serves businesses across India.</p>

            <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center justify-center text-center">
                <div
                    className="section-heading heading-left text-gray-900 lg:w-1/2"
                    data-sal="slide-right"
                    data-sal-duration="1000"
                    data-sal-delay="300"
                >
                    <h1 className="title text-5xl font-extrabold mb-6 leading-tight">
                        Best Solutions for Your Business
                    </h1>
                    <p className="text-xl max-w-lg leading-relaxed mx-auto">
                        Masterwiz Technologies is a full-service digital agency with global capabilities in web design and development, marketing, and branding. We partner with clients to unlock value through creativity, technology, and strategic thinking.
                    </p>
                    <Link to="/contact">
                        <button className="mt-8 px-8 py-3 bg-blue-600 text-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:bg-blue-500 hover:shadow-2xl">
                            Get in Touch
                        </button>
                    </Link>
                </div>

                <div
                    className="banner-thumbnail lg:w-1/2 mt-10 lg:mt-0"
                    data-sal="slide-up"
                    data-sal-duration="1000"
                    data-sal-delay="300"
                >
                    <img
                        src={bannerThumb}
                        alt="Banner Thumbnail"
                        className="w-full max-w-2xl h-auto rounded-xl transform hover:scale-105 transition-transform duration-300 animate-fade-in-up mx-auto lg:mx-0"
                    />
                </div>
            </div>
        </div>
    );
};

export default BreadcrumbBanner;
