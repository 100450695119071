import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';
// import logo from '../images/logo.png';
import fb from '../images/social/fb.png';
import link from '../images/social/in.png';
import yt from '../images/social/yt.png';
import ins from '../images/social/ins.png';
import ind from '../images/social/ind.png';
// import tw from '../images/social/tw.png';
import wt from '../images/social/wt.png';

const Contact = () => {
    useDocTitle('Masterwiz Technology - ContactUs');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const clearErrors = () => {
        setErrors([]);
    };
    const map = {
        border: '0px',
        height: '550px',
        width: '-webkit-fill-available'
    };
    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    const iconSize = "h-9 w-9";

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';

        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            message: message,
        };

        axios.post('https://live-testing.mymaster11.com/pages/masterwiz/info', data)
            .then((response) => {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'Send Message';
                clearInput();
                Notiflix.Report.success(
                    'Success',
                    'Your message has been sent successfully!',
                    'Okay',
                );
            })
            .catch((error) => {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'Send Message';
                Notiflix.Report.failure(
                    'Error',
                    'Failed to send your message. Please try again.',
                    'Okay',
                );
                console.error('Error sending form data:', error);
            });
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <h1 class="sr-only">Contact the Best Tech Company in Varanasi</h1>
            <p class="sr-only">Reach out to Masterwiz Technologies, the leading top tech company in Varanasi, Uttar Pradesh. We offer IT solutions, software development, and digital transformation services.</p>

            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <form onSubmit={sendEmail}>
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Send us a message</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-blue-50 text-gray-900 mt-2 p-3 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-blue-50 text-gray-900 mt-2 p-3 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-blue-50 text-gray-900 mt-2 p-3 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-blue-50 text-gray-900 mt-2 p-3 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        type="tel"
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) => {
                                            const phoneValue = e.target.value;
                                            // Only allow numbers and ensure length is between 10 and 13
                                            if (phoneValue.length <= 13 && /^[0-9]*$/.test(phoneValue)) {
                                                setPhone(phoneValue);
                                            }
                                        }}
                                        onKeyUp={clearErrors}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    placeholder="How can we help you?*"
                                    className="w-full h-32 bg-blue-50 text-gray-900 mt-2 p-3 rounded-lg border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                    required
                                ></textarea>
                            </div>
                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-gray-700 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                    <div
                        className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">

                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Office Address</h2>
                                    <p className="text-gray-400">A41, Patrakpuram Colony,  <br />

                                        Bhojuveer, Varanasi,

                                        Uttar Pradesh 221002 </p>
                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Call Us</h2>
                                    <p className="text-gray-400">Tel: +91 9151777311</p>

                                    <div className='mt-5'>
                                        <h2 className="text-2xl">Send an E-mail</h2>
                                        <p className="text-gray-400">masterwiztechno@gmail.com</p>
                                        <p className="text-gray-400">hr@masterwiztechnologies.com</p>
                                    </div>

                                </div>
                            </div>

                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center space-x-4">
                                    <li>
                                        <a href="https://wa.me/919151777311" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={wt}
                                                alt="WhatsApp"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <Link to="#" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={tw}
                                                alt="Twitter"
                                                className={`${iconSize} object-contain rounded-full`}
                                            />
                                        </Link>
                                    </li> */}

                                    <li>
                                        <a href="https://www.facebook.com/MasterwizTechnologies?mibextid=ZbWKwL" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={fb}
                                                alt="Facebook"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/masterwiztechnologies?igsh=N2s1ZGdiYzZ1aDE2" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={ins}
                                                alt="Instagram"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@MasterwizTechnologies" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={yt}
                                                alt="YouTube"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/masterwiz-technologies-pvt-ltd/" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={link}
                                                alt="LinkedIn"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/en/" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={ind}
                                                alt="Skype"
                                                className={`${iconSize} object-contain rounded-full`}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115379.01720308252!2d82.89054127242787!3d25.351391772497237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2d86611eefa9%3A0xc3c38d5544d74bb0!2sMasterwiz%20Technologies%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1728546553383!5m2!1sen!2sin"
                                title="Masterwiz Technologies - Company Route"
                                style={map}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>


    )
}

export default Contact;