import React from 'react';
import bannerThumb from '../../images/about-2.png';

const TeamArea = () => {
    return (
        <section className="py-20 bg-gray-100">
            <div className="container mx-auto">
                <div className="flex flex-wrap items-center">
                    <div className="w-full lg:w-7/12 mb-8 lg:mb-0" data-sal="slide-right" data-sal-duration="800" data-sal-delay="100">
                        <div className="about-team relative">
                            <div className="flex justify-center items-center">
                                <img src={bannerThumb} alt="Team" className="w-7/12 h-auto" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-5/12" data-sal="slide-left" data-sal-duration="800" data-sal-delay="100">
                        <div className="about-team pl-4">
                            <div className="section-heading text-left">
                                <span className="text-primary text-xl font-semibold mb-2 inline-block text-red-700">Our Team</span>
                                <h2 className="text-4xl font-bold leading-tight mt-4">
                                    Alone you can do<br />
                                    so little; together<br />
                                    we can do
                                    so much.
                                </h2>
                                <p className="mt-6 text-lg text-gray-700">
                                    To deliver a great Product, Masterwiz Platform has a <br/> unique workflow process that works to help build products <br/> easier, faster, and more efficient.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamArea;
