import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/logo.png';
import fb from '../images/social/fb.png';
import link from '../images/social/in.png';
import yt from '../images/social/yt.png';
import ins from '../images/social/ins.png';
import ind from '../images/social/ind.png';
import tw from '../images/social/tw.png';
import wt from '../images/social/wt.png';
const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Toggle the visibility of the "Back to Top" button
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const iconSize = "h-9 w-9";
    // Scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
            <footer>
                <h1 class="sr-only">Best IT Solutions Provider in Varanasi</h1>
                <h2 class="sr-only">Leading Digital Transformation Company in Uttar Pradesh</h2>
                <h3 class="sr-only">Custom Software Development in Varanasi</h3>
                <h4 class="sr-only">Top Mobile App Development Company in Varanasi</h4>
                <h5 class="sr-only">Affordable Web Development Services in UP, India</h5>
                <h6 class="sr-only">Expert Fantasy Sports Tech Company in Varanasi</h6>
                <p class="sr-only">Masterwiz Technologies is a top-rated tech company in Varanasi, Uttar Pradesh, India. We specialize in custom software solutions, mobile app development, web design, and digital transformation services. As a leading IT company, we offer expert services in e-commerce solutions, fantasy sports platforms, and scalable tech development. Trusted by startups and enterprises across Varanasi, we are your go-to choice for innovative digital solutions.</p>

                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
                    {/* Existing footer content */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg max-w-full lg:max-w-xs mx-auto">
                                {/* Center the image */}
                                <div className="flex justify-center mb-4">
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        className="h-10 w-auto sm:h-10 md:h-12 lg:h-16 xl:h-20"
                                    />
                                </div>
                                <div className="text-md font-medium text-gray-600 space-y-2">
                                    <h5 className="leading-tight">
                                        +91 9151777311<br />
                                        masterwiztechno@gmail.com
                                    </h5>
                                    <p>A41 Patrakarpuram Colony</p>
                                    <p>Bhojuveer, Varanasi</p>
                                    <p>U.P 221002, India</p>
                                </div>
                            </div>
                        </div>


                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/about" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/services" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/careers" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Careers</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/blogs" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Blog</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/contact" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Digital Marketing & Analytics Solutions</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-xl mb-6">
                                Be Developed and Discovered
                            </div>
                            <div className="text-md font-medium mb-4 ">We function for IT solutions and Marketing results that help any business to outgrow in their required space.</div>
                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center space-x-4">
                                    <li>
                                        <a href="https://wa.me/919151777311" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={wt}
                                                alt="WhatsApp"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <Link to="#" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={tw}
                                                alt="Twitter"
                                                className={`${iconSize} object-contain rounded-full`}
                                            />
                                        </Link>
                                    </li> */}

                                    <li>
                                        <a href="https://www.facebook.com/MasterwizTechnologies?mibextid=ZbWKwL" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={fb}
                                                alt="Facebook"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/masterwiztechnologies?igsh=N2s1ZGdiYzZ1aDE2" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={ins}
                                                alt="Instagram"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@MasterwizTechnologies" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={yt}
                                                alt="YouTube"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/masterwiz-technologies-pvt-ltd/" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={link}
                                                alt="LinkedIn"
                                                className={`${iconSize} object-contain`}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/en/" className="flex justify-center items-center rounded-full shadow transition-transform duration-150 ease-in-out hover:scale-110">
                                            <img
                                                src={ind}
                                                alt="Skype"
                                                className={`${iconSize} object-contain rounded-full`}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="/#hero"
                                    className=" hover:text-gray-900"
                                >
                                    Masterwiz Technologies
                                </HashLink>. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    onClick={() => window.open('https://wa.me/919151777311', '_blank')}
                    className="fixed top-1/2 right-0 transform -translate-y-1/2 bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-700 transition duration-300 animate-[bounce_1.5s_infinite_10s]"
                    aria-label="WhatsApp"
                >
                    <svg className="w-8 h-8 fill-current " viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 2C8.268 2 2 8.268 2 16c0 2.987.87 5.779 2.463 8.135L2 30l6.029-2.415C11.243 29.15 13.56 30 16 30c7.732 0 14-6.268 14-14S23.732 2 16 2zm0 26c-2.21 0-4.269-.69-5.996-1.857l-4.026 1.613.992-4.035C5.641 21.23 5 18.677 5 16c0-6.065 4.935-11 11-11s11 4.935 11 11-4.935 11-11 11zm4.476-7.745c-.346-.173-2.047-1.015-2.364-1.131-.318-.116-.551-.173-.785.173-.233.346-.9 1.131-1.103 1.364-.202.232-.402.261-.747.087-.345-.173-1.455-.536-2.771-1.708-1.024-.913-1.715-2.04-1.914-2.385-.2-.345-.021-.532.151-.705.156-.154.346-.403.519-.605.174-.202.233-.346.346-.578.116-.231.058-.433-.029-.605-.087-.173-.784-1.892-1.073-2.588-.281-.676-.562-.581-.785-.59-.204-.007-.433-.01-.666-.01-.232 0-.607.087-.926.433-.318.346-1.213 1.186-1.213 2.894 0 1.708 1.242 3.356 1.415 3.591.173.231 2.437 3.721 5.911 4.927.826.284 1.47.453 1.972.58.828.211 1.58.182 2.176.11.663-.078 2.047-.837 2.336-1.644.288-.807.288-1.501.202-1.644-.086-.144-.318-.231-.665-.404z" />
                    </svg>
                </button>

                {isVisible && (
                    <>
                        <button
                            onClick={scrollToTop}
                            className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
                            aria-label="Scroll to top"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                            </svg>
                        </button>

                        {/* WhatsApp Button */}
                    </>

                )}

            </footer >
        </>
    );
};

export default Footer;
