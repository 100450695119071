import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import NavBar from '../components/Navbar/NavBar';
import content from '../images/Hire/1.png';
import graphic from '../images/Hire/2.png';
import customer from '../images/Hire/3.png';
import video from '../images/Hire/4.png';
import Footer from './Footer';
import hero from '../images/Hire/hero.png';
import Cta from './Cta';

const Career = () => {
    const navigate = useNavigate();

    const jobOpenings = [
        {
            role: 'Content Writer',
            description: `Excellent writing, editing, and proofreading skills. Strong research skills and attention to detail. Knowledge of SEO and content marketing best practices. Degree in English, Journalism, Communications, or a related field is preferred.`,
            location: 'Varanasi, UP, India',
            experience: '1+ years',
            image: content,
        },
        {
            role: 'Graphic Designer',
            description: `Proficient in Adobe Creative Suite (Photoshop, Illustrator, InDesign) and other graphic design software. Strong understanding of typography, color theory, layout, and visual hierarchy. Ability to work on multiple projects simultaneously and manage time efficiently.`,
            location: 'Varanasi, UP, India',
            experience: '2+ years',
            image: graphic,
        },
        {
            role: 'Customer Support',
            description: `Strong phone contact handling skills and active listening. Excellent communication and presentation skills. Ability to multi-task, prioritize, and manage time effectively.`,
            location: 'Varanasi, UP, India',
            experience: '1+ years',
            image: customer,
        },
        {
            role: 'Video Editor',
            description: `Experience in editing software packages (e.g., Adobe Premiere, Adobe After Effects, Final Cut Pro, DaVinci Resolve etc.). Edit video content to ensure logical sequencing and smooth running. Creative mind and storytelling skills. Demonstrable video editing ability with a strong portfolio.`,
            location: 'Varanasi, UP, India',
            experience: '2+ years',
            image: video,
        },
    ];

    const handleApplyClick = () => {
        navigate('/contact');
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="bg-gray-100 min-h-30 p-6 pt-20 md:pt-36">
                <header className="text-center py-16 md:py-32 bg-gradient-to-r from-blue-900 to-indigo-900 text-white rounded-lg shadow-lg mb-8 md:mb-12">
                    <h1 className="text-4xl md:text-5xl font-extrabold drop-shadow-lg animate-bounce">Join Our Team</h1>
                    <p className="text-base md:text-lg mt-2 md:mt-4 max-w-lg mx-auto">
                        Explore career opportunities at our company and become a part of our growing team.
                    </p>
                </header>

                <section className="max-w-5xl mx-auto mb-12 md:mb-16 px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-blue-900 text-center mb-6 md:mb-10">
                        BENEFITS OF WORKING WITH US!
                    </h2>
                    <p className="text-center text-lg md:text-xl text-gray-700 mb-6 md:mb-8">
                        BECAUSE YOU’LL LOVE WHAT WE DO
                    </p>
                    <div className="bg-white shadow-2xl rounded-xl p-6 md:p-10 text-gray-800 border-t-4 border-blue-900 flex flex-col md:flex-row items-center md:items-start">
                        <ul className="list-disc list-inside space-y-3 md:space-y-4 md:w-2/3">
                            <li className="text-base md:text-lg">Work as an Entrepreneur, you think you deliver.</li>
                            <li className="text-base md:text-lg">Work on more than just tech, technology advancements.</li>
                            <li className="text-base md:text-lg">Cool teaming process, no hierarchy.</li>
                            <li className="text-base md:text-lg">Explore and learn, we encourage asking questions.</li>
                            <li className="text-base md:text-lg">Balance work and fun, at Masterwiz Technologies people are assets!</li>
                        </ul>
                        <img
                            src={hero}
                            alt="A visual representation of the benefits of working with us"
                            className="w-full sm:w-1/2 md:w-5/12 h-auto object-cover mt-6 md:mt-0 md:ml-6"
                        />
                    </div>
                </section>
                <section className="max-w-6xl mx-auto mb-16 px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-blue-900 text-center mb-6 md:mb-10">Current Openings</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {jobOpenings.map((job, index) => (
                            <div
                                key={index}
                                className="bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
                            >
                                <img src={job.image} alt={job.role} className="w-30 h-30 " />
                                <div className="p-6">
                                    <h3 className="text-2xl font-bold text-blue-900">{job.role}</h3>
                                    <p className="mt-2 text-gray-600">{job.location}</p>
                                    {/* <p className="mt-2 text-gray-600">Experience: {job.experience}</p> */}
                                    {job.description && <p className="mt-4 text-gray-600">{job.description}</p>}
                                    {/* <button
                                        onClick={handleApplyClick}
                                        className="mt-6 bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-gray-800 transition"
                                    >
                                        Apply Now
                                    </button> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <div className="text-center mt-16">
                    <button
                        onClick={handleApplyClick}
                        className="bg-gradient-to-r from-blue-900 to-indigo-700 text-white py-3 px-8 rounded-full hover:from-gray-800 hover:to-black transition text-lg font-semibold shadow-lg animate-bounce"
                    >
                        Apply Now
                    </button>
                </div>
            </div>
            <Cta/>
            <Footer />
        </>
    );
};

export default Career;
