import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/web-dev.svg';

const Hero = () => {
    return (
        <>
            <div className="hero" id="hero">
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <h1 class="sr-only">Best IT Solutions Provider in Varanasi</h1>
                    <h2 class="sr-only">Leading Digital Transformation Company in Uttar Pradesh</h2>
                    <h3 class="sr-only">Custom Software Development in Varanasi</h3>
                    <h4 class="sr-only">Top Tech Company in Uttar Pradesh</h4>
                    <h5 class="sr-only">Best Software Company in Varanasi</h5>
                    <h6 class="sr-only">Top IT Services Provider in Varanasi, UP</h6>

                    <h1 class="sr-only">Best Mobile App Development Company in Varanasi</h1>
                    <h2 class="sr-only">Custom Web Development Company in Uttar Pradesh</h2>
                    <h3 class="sr-only">Leading Cloud Services Provider in Varanasi</h3>
                    <h4 class="sr-only">Expert Digital Marketing Company in Varanasi</h4>
                    <h5 class="sr-only">Affordable IT Solutions in Varanasi</h5>
                    <h6 class="sr-only">Trusted Software Outsourcing Company in India</h6>

                    <h1 class="sr-only">Top E-commerce Solutions Provider in Varanasi</h1>
                    <h2 class="sr-only">Fantasy Sports Technology Solutions in India</h2>
                    <h3 class="sr-only">Custom Website Design Company in Varanasi</h3>
                    <h4 class="sr-only">IT Consultancy Services in Uttar Pradesh</h4>
                    <h5 class="sr-only">Top Digital Marketing Agency in Varanasi</h5>
                    <h6 class="sr-only">Best SEO Company in Varanasi</h6>

                    <h1 class="sr-only">Software Company in Varanasi, Uttar Pradesh</h1>
                    <h2 class="sr-only">Top Digital Marketing Services in Varanasi</h2>
                    <h3 class="sr-only">Leading IT Company in India</h3>
                    <h4 class="sr-only">Top Cloud Computing Company in Varanasi</h4>
                    <h5 class="sr-only">Professional Website Development in Varanasi</h5>
                    <h6 class="sr-only">Mobile App Design Experts in Varanasi</h6>

                    <h1 class="sr-only">Top-rated IT Services Company in Varanasi</h1>
                    <h2 class="sr-only">Leading Technology Consulting Firm in UP</h2>
                    <h3 class="sr-only">Best Custom Software Development Services in India</h3>
                    <h4 class="sr-only">Mobile Application Development Company in Varanasi</h4>
                    <h5 class="sr-only">Top Fantasy Sports Development Company in Varanasi</h5>
                    <h6 class="sr-only">Web Application Development Services in UP</h6>

                    <p class="sr-only">
                        Masterwiz Technologies is the best IT solutions provider in Varanasi, offering a wide range of services such as custom software development, mobile app development, web design, and cloud computing. Our expertise includes fantasy sports platforms, e-commerce development, and scalable tech solutions. As the top tech company in Varanasi, we are trusted by startups and enterprises for digital transformation.
                    </p>

                    <p class="sr-only">
                        We specialize in delivering affordable and innovative technology solutions, making us the leading IT company in Uttar Pradesh. Our services include cloud-based application development, digital marketing, SEO, mobile app design, and website development, catering to businesses in Varanasi and across India.
                    </p>

                    <p class="sr-only">
                        Whether you need custom software, web development, or mobile app solutions, Masterwiz Technologies is your go-to tech company in Varanasi. Our services also include cloud services, IT consulting, digital marketing, and more, helping businesses grow in the digital era.
                    </p>


                    <div id="hero" className="flex flex-col lg:flex-row py-12 lg:py-16 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-6 md:text-5xl text-4xl font-extrabold text-blue-800 leading-tight tracking-wide">
                                Best IT Solutions<br />
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-purple-500 to-green-400">
                                    Perfect Place To Grow <br /> Your Business
                                </span>
                            </h1>
                            <div className="text-xl font-medium tracking-tight mb-8 text-gray-600">
                                <h2 className="text-4xl font-bold mb-4 text-blue-700">
                                    <span className="text-blue-600">Comprehensive Software Solutions</span> for Startups
                                </h2>
                                <p className="text-lg">
                                    We specialize in delivering <span className="font-semibold text-green-500">end-to-end services</span> for startups, including
                                    <span className=" text-purple-600"> Web and Mobile App Development</span>,
                                    <span className="italic"> cutting-edge design</span>, and
                                    <span className="font-bold text-yellow-500"> strategic marketing</span>, along with
                                    <span className="text-purple-600 "> Digital Marketing & Analytics Solutions</span>.
                                </p>
                            </div>
                            <div className="mb-4 space-x-0 md:space-x-3 md:mb-8">
                                <Link to="/contact" className="text-white bg-gradient-to-r from-blue-700 via-blue-500 to-blue-400 hover:bg-gradient-to-l hover:from-blue-600 hover:to-blue-500 inline-flex items-center justify-center w-full px-8 py-4 my-4 text-lg shadow-xl rounded-full sm:w-auto sm:mb-0 transition-all duration-300 transform hover:scale-105 hover:shadow-2xl">
                                    Learn More
                                    <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                                {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t-lg float-right duration-1000 w-full transform hover:scale-105 hover:rotate-2 transition-transform ease-in-out" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;
